<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" size="24" v-on="on" @click="volver">
        {{ volverIcon }}
      </v-icon>
    </template>
    <span>Atrás</span>
  </v-tooltip>
</template>

<script>
//import enums from "@/utils/enums/index.js";

export default {
  props: {
    routeToGo: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    volverIcon: "mdi-arrow-left-bold",
  }),
  methods: {
    volver() {
      this.routeToGo ? this.$router.push(this.routeToGo) : this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
