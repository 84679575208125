<template>
  <v-container class="pl-0 pr-0 py-0">
    <span class="the-title text-h4">{{ title }}</span>
  </v-container>
</template>
<script>
export default {
  name: "PageHeader",
  props: ["title"],
  data() {
    return {
      items: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.the-title {
  font-size: 1.8rem !important;
  color: #002942;
}
@media (max-width: 768px) {
  .the-title {
    font-size: 1.3rem !important;
  }
}
</style>
